import _debounce from "lodash/debounce"
import _isEqual from "lodash/isEqual"
import store from "@/store"
import _cloneDeep from "lodash/cloneDeep"
import _forEach from "lodash/forEach"
import { getFromStorage, uploadFile } from './firebase.js'
import { LogEvent } from "./analytics"
import BlobManager from "@/lib/blobManager.js"
// Debug flag - set to true to enable debug logging
const DEBUG = false;

// Debug logging function
const debugLog = (...args) => {
  if (DEBUG) {
    console.log('[ImageQueue]', ...args);
  }
};

let config = {
  version: 6, // Increased version to add new objectStore for upload files
  stores: [
    { name: 'images',
      keyPath: 'imageID',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'external',
      keyPath: 'fileID',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'h3tiles',
      keyPath: 'tile',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'featureTiles',
      keyPath: 'tile',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'uploadQueue',
      keyPath: 'id',
      indexes: [
        { name: 'time', path: 'ts', unique: false },
        { name: 'status', path: 'status', unique: false }
      ]
    },
    { name: 'uploadFiles', // New object store for upload files
      keyPath: 'id',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
  ]
}
let ready = false
let localDb = false

const dbErrorHandle = function (event) {
  console.log('Database error: ', event.target.errorCode, event)
}
const dbInit = (next) => {
  var request = indexedDB.open('trakk-assets', config.version)
  request.onerror = function () {
    alert('Error initialising database: your browser must support IndexedDB in order to cache files offline');
    next(true)
  }
  request.onupgradeneeded = (event) => {
    var db = event.target.result
    db.onerror = dbErrorHandle

    // db.deleteObjectStore('user')
    // db.createObjectStore('user')

    if (event.oldVersion >= 1) {
      config.stores.forEach((s) => {
        try {
          db.deleteObjectStore(s.name)
        } catch (err) {
          console.log(err);
        }
      })
    }
    config.stores.forEach((s) => {
      let resourceStore = db.createObjectStore(s.name, { keyPath: s.keyPath })
      s.indexes.forEach((i) => {
        resourceStore.createIndex(i.name, i.path, { unique: i.unique })
      })
    })
  }
  request.onsuccess = (event) => {
    localDb = event.target.result
    localDb.onerror = dbErrorHandle
    ready = true
    next()
  }
}
const putRecord = ({ objStore, key, data, ts, mimeType, version, status }) => {
  return new Promise((resolve, reject) => {
    if (!objStore || !key || !data || !ts) {
      let err = new Error('missing required parameters')
      console.warn(err, { objStore, key, data, ts, mimeType })
      return reject(err)
    }
    if (!mimeType) mimeType = ''
    const storeFile = () => {
      // Create record with standard fields plus status
      let record = { ts, mimeType, data, version, status }
      
      let tr = localDb.transaction(objStore, 'readwrite')
      let store = tr.objectStore(objStore)
      store.onerror = dbErrorHandle
      let keyPath = store.keyPath
      if(!keyPath) return console.error('objectStore ' + objStore + ' does not have keyPath')
      if(data[keyPath] !== undefined && data[keyPath] !== key) return console.warn('data[' + keyPath + '] does not match dbase key')
      record[keyPath] = key
      let put = store.put(record)
      put.onsuccess = () => { resolve(record) }
      put.onerror = (e) => {
        console.warn(e)
        reject(e)
      }
    }
    if (data instanceof Blob) {
      mimeType = data.type
      var reader = new FileReader()
      reader.addEventListener("loadend", function () {
        data = reader.result
        storeFile()
      });
      reader.readAsArrayBuffer(data);
    } 
    else {
      if (typeof data === 'object') data = JSON.stringify(data)
      if(typeof data !== 'string') {
        let err = new Error('invalid data type for local storage')
        console.warn(err, { objStore, key, data, ts, mimeType })
        return reject(err)
      }
      storeFile()
    }
  })
}
const getRecord = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    if (!objStore || !key) {
      let err = new Error('missing required parameters')
      return reject(err)
    }
    let tr = localDb.transaction(objStore, 'readwrite')
    let get = tr.objectStore(objStore).get(key)
    get.onsuccess = (e) => {
      resolve(get.result || false)
    }
    get.onerror = (e) => {
      console.warn(e)
      reject(false)
    }
  })
}
const getFile = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    getRecord({ objStore, key })
    .then((record) => {
      if (!record) return resolve(false)
      let file = new Blob([record.data], { type: record.mimeType })
      
      let result = {
        key,
        file,
        ts: record.ts,
        version: record.version || 'default'
      }

      // Create a content ID based on objStore, key, and timestamp
      // This allows reusing the same blob URL for the same content
      const contentId = `${objStore}-${key}-${record.ts}`;
      
      // Use BlobManager to create and track the blob URL
      result.url = BlobManager.createURL(file, {
        owner: 'localDB',
        purpose: `${objStore}-${key}`,
        contentId: contentId,
        expireAfter: 600000 // Auto-expire after 10 minutes of inactivity
      })
      
      resolve(result)
    })
    .catch((err) => {
      reject(err)
    })
  })
}
const downloadFile = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    getFile({ objStore, key })
    .then(async (record) => {
      if (!record) {
        console.log('Could not download, file not found in local cache')
        return reject()
      }
      let fileName = record.key
      switch(record.file.type) {
        case 'application/pdf':
          fileName += '.pdf'
          break;
        case 'application/json':
          fileName += '.json'
          break;
        case 'image/jpeg':
          fileName += '.jpg'
          break;
        case 'image/png':
          fileName += '.png'
          break;
        default:
          console.log('unknown type:', record.file.type)
      }
      if (record.file.type === 'application/pdf') {
        resolve({
          message: 'success',
          file: {
            url: record.url,
            type: record.file.type,
            name: fileName
          }
        })
      } else {
        let a = window.document.createElement("a");
        a.href = record.url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        
        // We don't revoke the URL here because the download might still be in progress
        // The BlobManager will automatically clean it up after the expireAfter time
        
        resolve('success')
      }
      
      LogEvent('image_download')
    })
    .catch((err) => {
      console.log('local db download err')
      console.log(err)
      reject(err)
    })
  })
}
const cacheFileExternal = async ({ fileID, src, ts }) => {
  // console.log('Cache file external', { fileID, src, ts })
  let key = fileID
  let objStore = 'external'
  let cacheRecord = await getRecord({ objStore, key })
  let currentTs = new Date((cacheRecord || {}).ts || 0)
  let storeTs = new Date(ts)
  if (currentTs > storeTs) {
    let cacheFile = await getFile({ objStore, key })
    return cacheFile
  }
  let url = ''
  // let url = 'https://australia-southeast1-trakkasset.cloudfunctions.net/proxy/'
  url += src.startsWith('http') ? '' : 'https://'
  url += src
  const fetchResponse = await fetch(url)
  .catch((err) => { 
    // console.warn(err)
    return false 
  })
  // console.log(fetchResponse)
  let record = { objStore, key, ts: new Date(), version: 'default' }
  if(!fetchResponse || !fetchResponse.ok) {
    record.data = { error: 'fetch error', status: fetchResponse.status }
    record.mimeType = 'external/nofetch'
  }
  else {
    record.data = await fetchResponse.blob()
  }
  // console.log(fetchResponse)
  const putResult = await putRecord(record)
    .catch((err) => {
      console.warn(err)
      return false
    })
  if(!putResult) return { error: 'localDB error' }
  // console.log(putResult)
  return putResult
}
const cacheFileFirebase = async ({ fileID, src, ts }) => {
  let dbPath = src.split('/')
  let key = dbPath[2]
  let objStore = dbPath[1]
  if (!ts) return console.warn('ERROR: no ts in cacheList', { fileID, src, ts })
  if (dbPath.length > 3) {
    for (let i = 3; i < dbPath.length; i++) key += '/' + dbPath[i]
  }
  let ref = { objStore, key }
  let cacheRecord = await getRecord(ref)
  let currentTs = new Date((cacheRecord || {}).ts || 0)
  let storeTs = new Date(ts)
  if (currentTs < storeTs) {
    console.log(`Cache file: ${src}`)
    let storePath = `${objStore}/${key}`
    let version = 'default'
    let storageResult = await getFromStorage(storePath).catch(err => {
      console.warn(err)
      return null
    })
    // console.log(storageResult)
    if(!storageResult) return false
    putRecord({ objStore: dbPath[1], key, data: storageResult, ts, version }).catch(err => {
      if (err) {
        console.warn(err)
        return false
      }
    })
    return true
  }
}

// manage cachelist
let cacheNowStatus = 'idle'
let lastCacheList = []
const cacheNow = () => {
  cacheNowStatus = 'running'
  let cacheList = store.getters.cacheList
  lastCacheList = _cloneDeep(cacheList)
  let fileRefs = []
  let pushRef = (key) => {
    let dbRef = store.getters.imageByID(key)
    if(dbRef) fileRefs.push({ fileID: dbRef.imageID, src: dbRef.src, ts: dbRef.ts })
    else {
      dbRef = store.state.Db.files[key]
      if(dbRef) fileRefs.push({ fileID: dbRef.fileID, src: dbRef.src, ts: dbRef.ts })
    } 
  }
  cacheList.forEach(pushRef)
  fileRefs.forEach((meta) => {
    if (typeof meta.src === 'string') {
      if (meta.src.startsWith('gs:')) cacheFileFirebase(meta)
      else cacheFileExternal(meta)
    } else if (Array.isArray(meta.src)) {
      meta.src.forEach((src, i) => {
        if (src.startsWith('gs:')) cacheFileFirebase({ src, ts: meta.ts })
        else cacheFileExternal({ fileID: meta.fileID + '-' + i, src, ts: meta.ts})
      })
    }
  })
  cacheNowStatus = setTimeout(() => { cacheNowStatus = 'idle' }, 500)
}
const cacheUpdate = _debounce(() => {
  let newCacheList = store.getters.cacheList
  if(!_isEqual(lastCacheList, newCacheList)) {
    if(cacheNowStatus === 'idle') cacheNow()
    else {
      setTimeout(cacheUpdate, 1000)
    }
  }
}, 500) 


// Image upload queue management
let processingUpload = null;
let uploadQueueInterval = null;
let uploadPromises = {}; // Store promises for each upload

// Add an image to the upload queue
const addToImageUploadQueue = async (data, file) => {
  debugLog('Adding image to upload queue', { imageID: data?.imageID, fileSize: file?.size });
  
  if (!data || !data.imageID) {
    console.warn('Invalid image data for queue', data);
    return Promise.reject(new Error('Invalid image data'));
  }
  
  if (!file) {
    console.error('File is required for image upload');
    return Promise.reject(new Error('File is required for image upload'));
  }

  // Return a promise that resolves only after the file is stored in IndexedDB
  return new Promise(async (resolve, reject) => {
    try {
      const id = `upload_${Date.now()}_${data.imageID}`;
      const timestamp = Date.now();
      debugLog('Created queue item ID', id);
      
      // Create promise that will be resolved when the upload is completed
      let uploadedResolve, uploadedReject;
      
      const uploadPromise = new Promise((resolveUpload, rejectUpload) => {
        uploadedResolve = resolveUpload;
        uploadedReject = rejectUpload;
      });
      
      // Store the promise for this upload
      uploadPromises[id] = { resolve: uploadedResolve, reject: uploadedReject };
      
      
        // Store file data in the uploadFiles store
        debugLog('Storing file data in uploadFiles store', { id });
        await putRecord({
          objStore: 'uploadFiles',
          key: id,
          data: file,
          ts: timestamp,
          mimeType: file.type
        });
        debugLog('File data stored successfully', { id });
        
        // Store the queue item in the uploadQueue store
        debugLog('Storing queue item in uploadQueue store', { id, imageID: data.imageID });
        await putRecord({
          objStore: 'uploadQueue',
          key: id,
          data,
          ts: timestamp,
          status: 'pending'
        });
        debugLog('Queue item stored successfully', { id });
        
        // Start the queue processor AFTER the file is stored in IndexedDB
        if (!uploadQueueInterval) {
          debugLog('Starting queue processor after file is stored');
          startUploadQueueProcessor();
        }
        
        // Resolve the promise with the queued status
        const result = {
          status: 'queued',
          imageID: data.imageID,
          queueId: id,
          uploadPromise
        };
        
        debugLog('Resolving queue promise', { id, imageID: data.imageID });
        resolve(result);
      } catch (error) {
        debugLog('Error storing data in IndexedDB', error);
        console.error('Failed to add image to upload queue', error);
        uploadedReject(error);
        delete uploadPromises[id];
        reject(error);
      }
  });
};

// Start the upload queue processor
const startUploadQueueProcessor = () => {
  if (uploadQueueInterval) return; // Already running
  
  // Process the queue every second
  uploadQueueInterval = setInterval(processNextInUploadQueue, 1000);
  
  // Start processing immediately
  processNextInUploadQueue();
};

// Process the next item in the upload queue
const processNextInUploadQueue = async () => {
  // If already processing an upload, wait for it to complete
  if (processingUpload) {
    debugLog('Already processing an upload, waiting...', processingUpload);
    return;
  }
  
  try {
    debugLog('Looking for the oldest pending upload...');
    // Get the oldest pending upload from the queue
    const queueItem = await getOldestPendingUpload();
    
    if (!queueItem) {
      debugLog('No pending uploads found, stopping processor');
      // Queue is empty, stop the processor
      clearInterval(uploadQueueInterval);
      uploadQueueInterval = null;
      return;
    }
    const imageData = JSON.parse(queueItem.data);
    
    debugLog('Found pending upload to process', { id: queueItem.id, imageID: imageData.imageID });
    processingUpload = queueItem.id;
    
    // Update status to processing
    await updateUploadStatus(queueItem.id, 'processing');
    debugLog('Updated status to processing', queueItem.id);
    
    // Get the file data from the uploadFiles store
    debugLog('Retrieving file data from uploadFiles store', { id: queueItem.id });
    const fileRecord = await getFile({ objStore: 'uploadFiles', key: queueItem.id });
    
    if (!fileRecord || !fileRecord.file) {
      const errorMsg = `No file data found for image ${imageData.imageID}`;
      console.warn(errorMsg);
      await updateUploadStatus(queueItem.id, 'error', errorMsg);
      
      // Reject the upload promise if it exists
      if (uploadPromises[queueItem.id]) {
        uploadPromises[queueItem.id].reject(new Error(errorMsg));
        delete uploadPromises[queueItem.id];
      }
      
      processingUpload = null;
      return;
    }
    
    debugLog('File data retrieved successfully', { id: queueItem.id, fileSize: fileRecord.file.size });
    const file = fileRecord.file;
    
    // Parse the image data
    
    try {
      // Process the upload
      const result = await handleImageUpload(imageData, file);
      
      // Update status to completed
      await updateUploadStatus(queueItem.id, 'completed');
      
      // Clear the upload file
      await removeQueueUpload(queueItem.id);

      // Resolve the upload promise if it exists
      if (uploadPromises[queueItem.id]) {
        uploadPromises[queueItem.id].resolve(result);
        delete uploadPromises[queueItem.id];
      }
    } catch (error) {
      console.error('Error processing upload', error);
      await updateUploadStatus(queueItem.id, 'error', error.message);
      
      // Reject the upload promise if it exists
      if (uploadPromises[queueItem.id]) {
        uploadPromises[queueItem.id].reject(error);
        delete uploadPromises[queueItem.id];
      }
    }
    
    // Clear processing flag
    processingUpload = null;
  } catch (error) {
    console.error('Error processing upload queue', error);
    
    if (processingUpload) {
      await updateUploadStatus(processingUpload, 'error', error.message);
      
      // Reject the upload promise if it exists
      if (uploadPromises[processingUpload]) {
        uploadPromises[processingUpload].reject(error);
        delete uploadPromises[processingUpload];
      }
      
      processingUpload = null;
    }
  }
};

// Get the oldest pending upload from the queue
const getOldestPendingUpload = () => {
  return new Promise((resolve, reject) => {
    if (!localDb) {
      return reject(new Error('Database not initialized'));
    }
    
    const transaction = localDb.transaction(['uploadQueue'], 'readonly');
    const store = transaction.objectStore('uploadQueue');
    const timeIndex = store.index('time');
    
    // Use a cursor to get items sorted by timestamp (oldest first)
    const request = timeIndex.openCursor(null);
    
    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const item = cursor.value;
        
        if (item.status === 'pending') {
          // Found the oldest pending item
          resolve(item);
        } else {
          // Continue to the next item
          cursor.continue();
        }
      } else {
        // No pending items found
        resolve(null);
      }
    };
    
    request.onerror = (event) => {
      console.error('Error getting pending uploads', event);
      reject(event.target.error);
    };
  });
};

// Update the status of an upload in the queue
const updateUploadStatus = (id, status, errorMessage = null) => {
  return new Promise((resolve, reject) => {
    if (!localDb) {
      return reject(new Error('Database not initialized'));
    }
    
    const transaction = localDb.transaction(['uploadQueue'], 'readwrite');
    const store = transaction.objectStore('uploadQueue');
    const request = store.get(id);
    
    request.onsuccess = (event) => {
      const queueItem = event.target.result;
      if (!queueItem) {
        return reject(new Error(`Queue item ${id} not found`));
      }
      
      // Only update the status, don't modify the data
      debugLog('Updating upload status', { id, status, errorMessage });
      
      // Use putRecord to ensure status is properly indexed
      putRecord({
        objStore: 'uploadQueue',
        key: id,
        data: queueItem.data, // Keep the original data
        ts: queueItem.ts,
        status: status // Update the status
      })
      .then(() => {
        // If there's an error message, store it in a separate record
        if (errorMessage) {
          debugLog('Storing error message', { id, errorMessage });
          putRecord({
            objStore: 'uploadQueue',
            key: `${id}_error`,
            data: { message: errorMessage },
            ts: Date.now()
          })
          .catch(err => console.warn('Failed to store error message', err));
        }
        resolve();
      })
      .catch((error) => reject(error));
    };
    
    request.onerror = (event) => {
      console.error('Error updating upload status', event);
      reject(event.target.error);
    };
  });
};

// Handle the actual image upload process
const handleImageUpload = async (imageData, file) => {
  debugLog('Handling image upload', { imageID: imageData.imageID, src: imageData.src, fileSize: file.size });
  
  // Import the uploadFile function from firebase.js
  
  
  // Check if we need to upload to Firebase Storage
  if (typeof imageData.src === 'string' && imageData.src.startsWith('gs:')) {
    const storagePath = imageData.src.substring(3); // Remove 'gs:'
    debugLog('Uploading to Firebase Storage', { storagePath, fileSize: file.size });
    
    // Upload the file to Firebase Storage
    const uploadResult = await uploadFile(storagePath, file);
    debugLog('Firebase upload result', uploadResult);
    
    if (uploadResult.state !== 'success') {
      const errorMsg = `Failed to upload file: ${uploadResult.error || 'Unknown error'}`;
      debugLog('Firebase upload failed', errorMsg);
      throw new Error(errorMsg);
    }
    
    // Update the timestamp in the image data
    imageData.ts = uploadResult.ts;
    debugLog('Updated timestamp in image data', imageData.ts);
  } else {
    debugLog('No Firebase upload needed, src does not start with gs:', imageData.src);
  }
  
  // Now dispatch to Vuex to update the database (use setImageData to avoid recursion)
  debugLog('Dispatching to Vuex to update database', { imageID: imageData.imageID });
  await store.dispatch('setImageData', imageData);
  debugLog('Database update complete');

  return { status: 'success', imageID: imageData.imageID };
};

// Function for components to directly add an image to the queue
// Returns a promise that resolves when the upload is complete
const waitUpload = async (data, file) => {
  debugLog('waitUpload called', { imageID: data?.imageID, fileSize: file?.size });
  try {
    const promises = await addToImageUploadQueue(data, file);
    debugLog('Image added to queue, waiting for completion', { imageID: data?.imageID, queueId: promises.queueId });
    // Return the uploaded promise which resolves when the upload is complete
    return promises.uploadPromise;
  } catch (error) {
    debugLog('waitUpload failed', error);
    return Promise.reject(error);
  }
};

// Function for Vuex to add an image to the queue
// Returns a promise that resolves when the image is added to the queue
const queueUpload = async (data, file) => {
  debugLog('queueUpload called from Vuex', { imageID: data?.imageID, fileSize: file?.size });
  try {
    // Just add to queue and return immediately - don't wait for upload to complete
    const result = await addToImageUploadQueue(data, file);
    debugLog('Image queued successfully', { imageID: data?.imageID, queueId: result.queueId });
    return result;
  } catch (error) {
    debugLog('queueUpload failed', error);
    return Promise.reject(error);
  }
};

// Remove an item from the upload queue and remove the associated file
const removeQueueUpload = async (id) => { 
  debugLog('Removing item from upload queue', id);
  
  return new Promise((resolve, reject) => {
    if (!localDb) {
      return reject(new Error('Database not initialized'));
    }
    
    // Create a transaction covering both stores
    const transaction = localDb.transaction(['uploadQueue', 'uploadFiles'], 'readwrite');
    
    // Get store references
    const queueStore = transaction.objectStore('uploadQueue');
    const filesStore = transaction.objectStore('uploadFiles');
    
    // Delete from uploadQueue
    const queueDelete = queueStore.delete(id);
    queueDelete.onerror = (event) => {
      console.error('Failed to delete queue item', event);
      // Continue with other deletions even if this one fails
    };
    
    // Delete any error message
    const errorDelete = queueStore.delete(`${id}_error`);
    errorDelete.onerror = (event) => {
      console.warn('Failed to delete error message', event);
      // Non-critical, continue with other deletions
    };
    
    // Delete from uploadFiles
    const fileDelete = filesStore.delete(id);
    fileDelete.onerror = (event) => {
      console.error('Failed to delete file data', event);
      // Continue with transaction
    };
    
    // Handle completion
    transaction.oncomplete = () => {
      debugLog('Successfully removed upload data', { id });
      resolve();
    };
    
    // Handle errors
    transaction.onerror = (event) => {
      console.error('Failed to remove upload data', event);
      reject(event.target.error);
    };
  });
}


export {
  localDb,
  ready,
  dbInit,
  putRecord,
  getRecord,
  getFile,
  downloadFile,
  cacheFileExternal,
  cacheFileFirebase,
  cacheUpdate,
  waitUpload,
  queueUpload,
  removeQueueUpload,
  startUploadQueueProcessor
}
