/**
 * BlobManager - Utility for managing blob URLs to prevent memory leaks
 * 
 * This utility helps track and clean up blob URLs to prevent memory leaks
 * when working with large numbers of images, files, and binary data.
 */

// Registry to track all created blob URLs and their metadata
const urlRegistry = new Map();

// Content-based cache to avoid creating multiple blob URLs for the same content
// Maps content identifiers to existing blob URLs
const contentCache = new Map();

// Debug mode flag
const DEBUG = true;

/**
 * Create a blob URL with tracking
 * @param {Blob} blob - The blob to create a URL for
 * @param {Object} options - Options for the blob URL
 * @param {string} options.owner - Component or module that owns this URL (for debugging)
 * @param {string} options.purpose - Purpose of this URL (e.g., 'thumbnail', 'preview')
 * @param {number} options.expireAfter - Time in ms after which URL should be auto-revoked (optional)
 * @param {string} options.contentId - Unique identifier for the content (e.g., 'objStore-key-timestamp')
 * @param {boolean} options.keepBlob - Whether to keep a reference to the blob
 * @returns {string} The created blob URL
 */
function createURL(blob, options = {}) {
  if (!blob) {
    console.error('BlobManager: Cannot create URL for null or undefined blob');
    return null;
  }

  // If a contentId is provided, check if we already have a URL for this content
  if (options.contentId && contentCache.has(options.contentId)) {
    const existingUrl = contentCache.get(options.contentId);
    
    // If the URL is still valid (exists in urlRegistry), reuse it
    if (urlRegistry.has(existingUrl)) {
      const metadata = urlRegistry.get(existingUrl);
      
      // Update the metadata to reflect this new usage
      metadata.lastUsed = Date.now();
      
      // Reset expiration if applicable
      if (metadata.expireAfter && options.expireAfter) {
        // Use the longer expiration time
        metadata.expireAfter = Math.max(metadata.expireAfter, options.expireAfter);
        
        if (DEBUG) {
          console.log(`BlobManager: Reusing URL ${existingUrl} for ${options.owner} (${options.purpose}) - Extended expiration`);
        }
      }
      
      // Add this owner to the list of owners if not already present
      if (!metadata.owners) {
        metadata.owners = new Set([metadata.owner]);
      }
      metadata.owners.add(options.owner || 'unknown');
      
      return existingUrl;
    } else {
      // URL was revoked, remove from content cache
      contentCache.delete(options.contentId);
    }
  }

  // Create a new blob URL
  const url = URL.createObjectURL(blob);
  
  // Register the URL with metadata
  urlRegistry.set(url, {
    createdAt: Date.now(),
    lastUsed: Date.now(),
    size: blob.size || 0,
    type: blob.type || 'unknown',
    owners: new Set([options.owner || 'unknown']), // Track all owners
    purpose: options.purpose || 'unknown',
    expireAfter: options.expireAfter || null,
    contentId: options.contentId || null,
    blob: options.keepBlob ? blob : null // Optionally keep reference to the blob
  });

  // If contentId is provided, cache the URL by content
  if (options.contentId) {
    contentCache.set(options.contentId, url);
  }

  if (DEBUG) {
    console.log(`BlobManager: Created URL ${url} for ${options.owner || 'unknown'} (${options.purpose || 'unknown'}) - Size: ${(blob.size / 1024).toFixed(2)} KB${options.contentId ? ' - ContentID: ' + options.contentId : ''}`);
  }

  // Set up auto-expiration if requested
  if (options.expireAfter && options.expireAfter > 0) {
    setTimeout(() => {
      revokeURL(url);
    }, options.expireAfter);
  }

  return url;
}

/**
 * Revoke a specific blob URL and remove it from the registry
 * @param {string} url - The blob URL to revoke
 * @returns {boolean} True if the URL was found and revoked, false otherwise
 */
function revokeURL(url) {
  if (!url || !urlRegistry.has(url)) {
    return false;
  }

  try {
    const metadata = urlRegistry.get(url);
    
    // Remove from content cache if it exists
    if (metadata.contentId && contentCache.has(metadata.contentId)) {
      contentCache.delete(metadata.contentId);
    }
    
    URL.revokeObjectURL(url);
    
    if (DEBUG) {
      const ownersList = Array.from(metadata.owners || ['unknown']).join(', ');
      console.log(`BlobManager: Revoked URL ${url} for [${ownersList}] (${metadata.purpose}) - Size: ${(metadata.size / 1024).toFixed(2)} KB`);
    }
    
    urlRegistry.delete(url);
    return true;
  } catch (error) {
    console.error('BlobManager: Error revoking URL', error);
    return false;
  }
}

/**
 * Revoke all blob URLs owned by a specific component or module
 * @param {string} owner - The owner whose URLs should be revoked
 * @returns {number} Number of URLs revoked
 */
function revokeURLsByOwner(owner) {
  if (!owner) {
    return 0;
  }

  let count = 0;
  
  // Find all URLs owned by this owner
  const urlsToProcess = [];
  urlRegistry.forEach((metadata, url) => {
    if (metadata.owners && metadata.owners.has(owner)) {
      urlsToProcess.push({ url, metadata });
    }
  });
  
  // Process each URL
  urlsToProcess.forEach(({ url, metadata }) => {
    // If this URL has multiple owners, just remove this owner
    if (metadata.owners && metadata.owners.size > 1) {
      metadata.owners.delete(owner);
      if (DEBUG) {
        console.log(`BlobManager: Removed owner ${owner} from URL ${url}, still used by ${metadata.owners.size} other owners`);
      }
    } else {
      // If this is the only owner, revoke the URL
      if (revokeURL(url)) {
        count++;
      }
    }
  });
  
  if (DEBUG && count > 0) {
    console.log(`BlobManager: Revoked ${count} URLs for owner ${owner}`);
  }
  
  return count;
}

/**
 * Revoke all blob URLs with a specific purpose
 * @param {string} purpose - The purpose of URLs to revoke
 * @returns {number} Number of URLs revoked
 */
function revokeURLsByPurpose(purpose) {
  if (!purpose) {
    return 0;
  }

  let count = 0;
  
  // Find all URLs with this purpose
  const urlsToRevoke = [];
  urlRegistry.forEach((metadata, url) => {
    if (metadata.purpose === purpose) {
      urlsToRevoke.push(url);
    }
  });
  
  // Revoke each URL
  urlsToRevoke.forEach(url => {
    if (revokeURL(url)) {
      count++;
    }
  });
  
  if (DEBUG && count > 0) {
    console.log(`BlobManager: Revoked ${count} URLs with purpose ${purpose}`);
  }
  
  return count;
}

/**
 * Get statistics about currently tracked blob URLs
 * @returns {Object} Statistics about tracked URLs
 */
function getStats() {
  let totalSize = 0;
  let countByOwner = {};
  let countByPurpose = {};
  let countByType = {};
  
  urlRegistry.forEach((metadata) => {
    totalSize += metadata.size;
    
    // Count by owner
    if (metadata.owners) {
      metadata.owners.forEach(owner => {
        countByOwner[owner] = (countByOwner[owner] || 0) + 1;
      });
    }
    
    // Count by purpose
    countByPurpose[metadata.purpose] = (countByPurpose[metadata.purpose] || 0) + 1;
    
    // Count by type
    countByType[metadata.type] = (countByType[metadata.type] || 0) + 1;
  });
  
  return {
    totalCount: urlRegistry.size,
    totalSize,
    countByOwner,
    countByPurpose,
    countByType
  };
}

/**
 * Clean up expired blob URLs
 * @param {number} olderThan - Clean up URLs older than this timestamp
 * @returns {number} Number of URLs cleaned up
 */
function cleanupExpired(olderThan = null) {
  const now = Date.now();
  const threshold = olderThan || now;
  
  let count = 0;
  const urlsToRevoke = [];
  
  urlRegistry.forEach((metadata, url) => {
    // Check if URL has expired based on expireAfter
    if (metadata.expireAfter && (metadata.createdAt + metadata.expireAfter < now)) {
      urlsToRevoke.push(url);
    }
    // Check if URL is older than threshold
    else if (metadata.createdAt < threshold) {
      urlsToRevoke.push(url);
    }
  });
  
  urlsToRevoke.forEach(url => {
    if (revokeURL(url)) {
      count++;
    }
  });
  
  if (DEBUG && count > 0) {
    console.log(`BlobManager: Cleaned up ${count} expired URLs`);
  }
  
  return count;
}

/**
 * Revoke all tracked blob URLs
 * @returns {number} Number of URLs revoked
 */
function revokeAll() {
  let count = 0;
  
  urlRegistry.forEach((_, url) => {
    if (revokeURL(url)) {
      count++;
    }
  });
  
  if (DEBUG) {
    console.log(`BlobManager: Revoked all ${count} URLs`);
  }
  
  return count;
}

// Set up periodic cleanup of expired URLs (every 5 minutes)
setInterval(() => {
  // Clean up URLs that are older than 30 minutes
  const thirtyMinutesAgo = Date.now() - (30 * 60 * 1000);
  cleanupExpired(thirtyMinutesAgo);
}, 5 * 60 * 1000);

export default {
  createURL,
  revokeURL,
  revokeURLsByOwner,
  revokeURLsByPurpose,
  getStats,
  cleanupExpired,
  revokeAll
};
